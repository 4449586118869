<template>
  <div class="home-resources">
    <h3 class="home-title">企业资源统计</h3>
    <span class="resources-num">存储用量</span>
    <div class="resources-number">{{storageSiz}}</div>
    <Row class="resources-project-num">
      <Col span="12" class="resources-p-num">项目数 {{itemData.project_count}}</Col>
      <Col span="12" class="resources-shots-num">拍摄数 {{itemData.shoot_count}}</Col>
    </Row>
  </div>
</template>
<script>

export default {
  name: 'HomeResources',
  props: {
    storageSiz:String,
   itemData:{
     storage:'',
     project_count:"",
     shoot_count:""
   },
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.home-resources {
  width: 35%;
  margin: 20px 20px 20px 24px;
  text-align: left;
  border-right: 1px dashed rgba(0, 0, 0, 0.1);

  .resources-num {
    margin-bottom: 4px;
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }
  .resources-number {
    height: 38px;
    font-size: 30px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 38px;
    margin-bottom: 20px;
  }
}
</style>
