<template>
<div>
     <!-- <el-breadcrumb class="breadcrumb-title" separator="/">
         <el-breadcrumb-item>首页</el-breadcrumb-item>
      </el-breadcrumb> -->
  <div class="home-page">
    <Row class="homeAll">
      <Col span="16">
        <Row clas="resources-left">
          <Col class="resources usage-resources">
           <template v-if="HomeObject.usage!=undefined"> 
            <HomeResources :storageSiz="storageSiz"  ref="HomeResources" :itemData='HomeObject.usage'></HomeResources>
             </template>
            <ResourcesPie></ResourcesPie>
          </Col>
          <Col class="resources usage-statistics">
            <UsageRtatistics :companyList='HomeObject'></UsageRtatistics>
          </Col>
          <Col class="resources quick_access">
          <QuickAccess></QuickAccess>
          </Col>
        </Row>
      </Col>
      <Col class="col_right" span="7">
        <Row justify="end">
          <Col class="ziyuan">
            <CompanyInformation :companyList='HomeObject'></CompanyInformation>
          </Col>
          <!-- <Col class="company-information">
          <costOverview :costOver='HomeObject'></costOverview>
          </Col> -->
        </Row>
      </Col>
    </Row>
  </div>
  </div>
</template>
<script>
import HomeResources from "./components/HomeResources";
import ResourcesPie from "./components/ResourcesPie";
import UsageRtatistics from "./components/UsageRtatistics";
import QuickAccess from "./components/QuickAccess";
import CompanyInformation from "./components/CompanyInformation";
import costOverview from "./components/costOverview";
import request from "@request/api/index";
import {getFileSize} from "@utils/getFileSize";
export default {
  components: {
    HomeResources,
    ResourcesPie,
    UsageRtatistics,
    QuickAccess,
    CompanyInformation,
    costOverview
  },
  data() {
    return {
      HomeObject:{
        inside_count:1
      },
      storageSiz:0
    };
  },
  created(){
    this.getHomeDate()
  },
  methods: {
    async getHomeDate(){
      try{
        let res = await request.enterprise.requestHome({});
        this.HomeObject=res.data.data
        this.storageSiz = this.HomeObject.usage.storage;
        this.storageSiz = this.storageSiz === 0 ? '0B' : getFileSize(this.storageSiz);
        this.HomeObject.inside_count=this.HomeObject.company_user_count-this.HomeObject.company_out_user_count
      }catch(err){
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home-page {
  height: 100%;
  padding-top:30px;
  .homeAll {
    .col_right{
      margin-left:20px;
    }
    .ziyuan {
      margin-bottom:20px;
      height: 188px;
      background: #ffffff;
      .resources-left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
  .usage-resources {
    display: flex;
    height: 188px;
    background: #ffff;
    border-radius: 8px;
  }
  .company-information {
    height: 188px;
    background: #ffffff;
    border-radius: 8px;
  }
  .usage-statistics {
    height: 500px;
    background: #ffff;
    border-radius: 8px;
  }
  .quick_access {
    height: 198px;
    background: #ffffff;
    border-radius: 8px;
  }
  .resources {
    margin-bottom: 20px;
  }
  .ResourcesPie {
    width: 65%;
  .ResourcesPie_item {
    width: 100%;
    height: 100%;
  }
}
}
</style>
