<template>
  <div class="quick_access">
    <h3 class="home-title">快捷入口</h3>
    <div class="quick_All flex-row flex-wrap">
      <div
        class="quick_item flex-row"
        @click="HomePageJump(index)"
        v-for="(item, index) in quickList"
        :key="'icon' + index"
      >
        <span
          class="item_icon iconfont iconfont_ico"
          :class="[item.className]"
        ></span>
        <span class="item_list">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeResources",
  props: {},
  data() {
    return {
      quickList: [
        {
          className: "iconyuangongguanli",
          name: "员工管理",
        },
        {
          className: "iconwaibulianxiren",
          name: "外部联系人",
        },
        {
          className: "iconxiangmuliebiao",
          name: "项目列表",
        },
        {
          className: "iconpinpaiguanli",
          name: "品牌管理",
        },
        {
          className: "iconziyuangailan",
          name: "资源概览",
        },
        // {
        //   className: "iconzhangdanguanli",
        //   name: "账单管理",
        // },
      ],
      RouterIndex:''
    };
  },
  computed: {},
  methods: {
    HomePageJump(index) {
      switch (index) {
        case 0:
          this.$router.push({ name: 'MembersDepartments'});
          break;
        case 1:
          this.$router.push({ name: 'ExternalContacts'});
          break;
        case 2:
           this.$router.push({ name: 'projectList'});
          break;
        case 3:
          this.$router.push({ name: 'Brand'});
          break;
           case 4:
          this.$router.push({ name: 'resource'});
          break;
           case 5:
          this.$router.push({ name: 'billList'});
          break;
        default:
          index = index;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.quick_access {
  padding: 20px 20px 0 20px;
  text-align: left;

  .quick_All {
    width: 100%;
    display: flex;
  }
 
  .quick_item {
    width: 17%;
    margin: 0 10px 12px 0;
    height: 50px;
    border-radius: 6px;
    padding-left: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .iconfont_ico {
      font-size: 20px;
      color: #3468fe;
      margin-right: 10px;
      background: rgba(52, 104, 254, 0.1);
      border-radius: 6px;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
   
  }
   .quick_item:hover{
     border: 1px solid #3468FE;
     color: #3468fe;
  }
}
.iconyuangongguanli {
  font-size: 20px;
}
</style>
