<template>
  <div class="Company_information">
    <div class="Company flex-row">
      <div class="company_logo iconfont iconqiyeguanli"></div>
      <div class="Company_info flex-column">
        <span class="company_title">{{ companyList.company_name }}</span>
        <span class="company_num"
          >企业编号：{{ companyList.company_number }}</span
        >
      </div>
    </div>
    <Row class="allBu" justify="center">
      <Col class="ivu-6" span="6">
        <div>部门数</div>
        <span>{{ companyList.company_department_count }}</span>
      </Col>
      <Col class="ivu-6" span="6">
        <div>总成员</div>
        <span>{{ companyList.company_user_count }}</span>
      </Col>
      <Col class="ivu-6" span="6">
        <div>内部成员</div>
        <span>{{companyList.inside_count}}</span>
      </Col>
      <Col class="ivu-6" span="6">
        <div>外部联系人</div>
        <span>{{ companyList.company_out_user_count }}</span>
      </Col>
    </Row>
  </div>
</template>
<script>
import request from "@request/api/index";
export default {
  name: "CompanyInformation",
  props: {
    companyList: {},
  },
  data() {
    return {
    };
  },
  created(){
 
  
  },
  computed: {},
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.Company_information {
  padding: 20px 20px 0 20px;
  .Company {
    margin-bottom: 47px;
    .company_logo {
      width: 44px;
      height: 44px;
      background-color: #F8F9FB;
      margin-right: 20px;
      line-height: 44px;
      text-align: center;
      font-size: 40px;
      border-radius: 6px;
      color:#3468FE;
    }
    .company_title {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    .company_num {
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 17px;
    }
  }
  .allBu {
    span {
      height: 24px;
      font-size: 20px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
  }
}
</style>
