<template>
  <!-- <el-col> -->
  <div class="ResourcesPie">
    <div
      class="ResourcesPie_item"
      ref="charts"
    ></div>
  </div>
</template>
<script>
import request from "@request/api/index";
import echarts from "echarts";
export default {
  name: "HomeResources",
  props: {
    pieItem: {},
  },
  data () {
    return {
      HomeObject: {},
    };
  },
  created () {
    this.$nextTick(function () {
      this.drawLine();
    });
  },
  methods: {
  async drawLine () {
      var bar_dv = this.$refs.charts;
      let myChart = this.$echarts.init(bar_dv);
      // 基于准备好的dom，初始化echarts实例
      //   let myChart = this.$echarts.init(document.getElementById('main'))
      // 绘制图表
      var option = {
        // 标题组件
        title: [
          {
            text: "文件数",
            subtext: "0",

            textStyle: {
              fontSize: 16,
              color: "#98999C",
              fontWeight: "600",
            },
            subtextStyle: {
              fontSize: 18,
              color: "#98999C",
              fontWeight: "800",
            },
            textAlign: "center",
            x: "19%",
            y: "40%",
          },
        ],
        tooltip: {
          // trigger: 'item',
          formatter: "<br/>{b}: {c} ({d}%)",
        },
        legend: {
          type: 'plain',
          top: '18%',
          left: '40%',
          right:'20%',
          orient: 'horizontal',
          icon: 'circle',
          align: 'auto',
          itemGap: 25,
          formatter: function (name) {
            const count = option.series[0].data.filter(x => x.name == name);
            return `${name} \n\n ${count[0].value}`
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ['20%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.2)",
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  // #3468FE'
                  var colorList = [
                    "#3468FE",
                    "#62CFE3",
                    "#FFAE33",
                    "#FFE9C4",
                    "#EAEFFE",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: [
              // { value:1,color:'#FFf'}
              { value: 0, name: "图片数" },
              { value: 0, name: "文档数" },
              { value: 0, name: "表格数" },
              { value: 0, name: "其他" },
            ],
          },
        ],
      };
      try{
     const resHome=await request.enterprise.requestHome({})
        this.HomeObject = resHome.data.data;
        option.series[0].data = [
          { value: this.HomeObject.video_count, name: "视频数" },
          { value: this.HomeObject.image_count, name: "图片数" },
          { value: this.HomeObject.doc_count, name: "文档数" },
          { value: this.HomeObject.excel_count, name: "表格数" },
          { value: this.HomeObject.other_count, name: "其他" },
        ];
        option.title[0].subtext = this.HomeObject.video_count + this.HomeObject.image_count + this.HomeObject.doc_count +
          this.HomeObject.excel_count + this.HomeObject.other_count + ""
        myChart.setOption(option);
    }catch(err){

    }
    },
  },
};
</script>
<style lang="scss" scoped>
.ResourcesPie {
  width: 65%;
  .ResourcesPie_infoAll {
    width: 40%;
  }
  .ResourcesPie_info {
    .icon_item {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      background-color: #ccc;
    }
    .text_item {
      width: 50px;
      height: 50px;
    }
  }
  .ResourcesPie_item {
    // width: 100%;
    width: 580px;
    height: 100%;
  }
}
</style>
