<template>
  <div class="UsageRtatistics">
    <div
      clss="home-user"
      style="padding:20px 0 0 24px"
    >
      <h3 class="home-title">使用统计</h3>
      <div class="data_number">
        <el-form>
          <el-form-item ref="formInline">
            <el-button-group>
              <el-button
                class="tab-data"
                :class="{ active: dateActive == 0 }"
                style="width:60px;height:24px;margin-right:10px;
                 margin-top:4px;text-align:center;
                 line-height:24px;border-radius: 6px;"
                size="small"
                @click="setSearchDate(0)"
              >今天</el-button>
              <el-button
                class="tab-data"
                :class="{ active: dateActive == 7 }"
                style="width:60px;height:24px;margin-right:10px;
              margin-top:4px;text-align:center;
              line-height:24px;border-radius: 6px;"
                size="small"
                @click="setSearchDate(7)"
              >近7天</el-button>
              <el-button
                class="tab-data"
                :class="{ active: dateActive == 30 }"
                style="width:60px;height:24px;margin-right:10px;
              margin-top:4px;text-align:center;
              line-height:24px;border-radius: 6px;"
                size="small"
                @click="setSearchDate(30)"
              >近30天</el-button>
              <el-date-picker
                v-model="dateValue"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                style="width: 236px"
                :picker-options='pickerOptions'
                @change="dataChange"
              >
              </el-date-picker>
            </el-button-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="Usage">
      <div class="UsageRtatistics_echart">
        <div
          class="UsageRtatistics_item"
          style="width:485px !important"  
          ref="charts"
        ></div>
      </div>

      <div class="UsageRtatistics_number flex-column">
        <div class="UsageRtatistics_number_icon flex-row">
          <div class="number_icon iconfont iconpinglun"></div>
          <div class="number_num flex-column">
            <div class="num_text">{{ companyList.comment_count }}</div>
            <div class="num_n">累计评价</div>
          </div>
        </div>
        <div class="UsageRtatistics_number_icon flex-row">
          <div class="number_icon iconfont iconfenxiang"></div>
          <div class="number_num flex-column">
            <div class="num_text">{{ companyList.share_count }}</div>
            <div class="num_n">累计分享</div>
          </div>
        </div>
        <div class="UsageRtatistics_number_icon flex-row">
          <div class="number_icon iconfont iconxiazai"></div>
          <div class="number_num flex-column">
            <div class="num_text">{{ companyList.download_count }}</div>
            <div class="num_n">累计下载</div>
          </div>
        </div>
        <div class="UsageRtatistics_number_icon flex-row">
          <div class="number_icon iconfont iconshangchuan"></div>
          <div class="number_num flex-column">
            <div class="num_text">{{ companyList.upload_count }}</div>
            <div class="num_n">累计上传</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import request from "@request/api/index";
import { getFormatDate } from '@utils/dateUtil.js';
export default {
  name: "UsageRtatistics",
  props: {
    companyList: {},
    pieItem: {},
  },
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          var now_date = new Date();//获取Date对象
          now_date.setHours(0);//设置小时
          now_date.setMinutes(0);//设置分钟
          now_date.setSeconds(0);//设置秒
          now_date.setMilliseconds(0);//设置毫妙
          var timestamp = now_date.getTime();//获取毫秒时间戳
          let dayTime = 24 * 60 * 60 * 1000;
          let result = time.getTime() > timestamp + dayTime || time.getTime() <= timestamp - 60 * dayTime;
          return result;
        },
      },
      UsageRtatisticsList: [
        {
          num: "1398",
          name: "累计评价",
        },
        {
          num: "1398",
          name: "累计分享",
        },
        {
          num: "1398",
          name: "累计下载",
        },
        {
          num: "1398",
          name: "累计上传",
        },
      ],
      HomeLineObject: {},
      dateActive: 7,
      dateValue: [],
    };
  },
  created () {
    this.$nextTick(function () {
      this.drawLine(this.dateValue[0], this.dateValue[1]);
    });
  },
  mounted () {
    this.setSearchDate(7);
  },
  methods: {

    dataChange (change) {
      this.drawLine(change[0], change[1])
      this.dateActive=-1

    },
  async drawLine (beforeDate, afterDate) {
      var bar_dv = this.$refs.charts;
      let myChart = this.$echarts.init(bar_dv);
      // 基于准备好的dom，初始化echarts实例
      //   let myChart = this.$echarts.init(document.getElementById('main'))
      // 绘制图表
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: 0,
          data: ["评论", "分享", "下载", "上传"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            // saveAsImage: {}
          },
        },
        xAxis: {
          type: "category",
          data: [],
          boundaryGap: false,
          axisTick: {
            lineStyle: {
              color: "#e9e9e9",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#e9e9e9",
            },
          },
          axisLabel: {
            color: "#6f6f6f",
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            //y轴刻度线
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#6f6f6f",
          },
        },
        series: [
          {
            data: [],
            name: "评论",
            type: "line",
            stack: "总量",
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#3468FE",
                },
              },
            },
          },
          {
            name: "分享",
            type: "line",
            stack: "总量",
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#62CFE3",
                },
              },
            },
          },
          {
            name: "下载",
            type: "line",
            stack: "总量",
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#FFAE33",
                },
              },
            },
          },
          {
            name: "上传",
            type: "line",
            stack: "总量",
            data: [],
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#D8D8D8",
                },
              },
            },
          },
        ],
      };
      try{
         let params = {
        startDate: getFormatDate(beforeDate == null ? this.dateValue[0] : beforeDate),
        endDate: getFormatDate(afterDate == null ? this.dateValue[1] : afterDate),
      };
    const HomeLine=await request.enterprise.requestHomeLine(params)
        this.HomeLineObject = HomeLine.data.data;
        option.series[0].data = this.HomeLineObject.comment_count;
        option.series[1].data = this.HomeLineObject.share_count;
        option.series[2].data = this.HomeLineObject.download_count;
        option.series[3].data = this.HomeLineObject.upload_count;
        option.xAxis.data = this.HomeLineObject.list;
        myChart.setOption(option);
        setTimeout(function () {
          window.onresize = function () {
            myChart.resize();
          }
        }, 200)
      }catch(err){
      }
    },
    setSearchDate (n) {
      this.dateActive = n;
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * n);
      this.dateValue = [start, end];
      this.$forceUpdate();
      this.drawLine();
    },
  },
};
</script>
<style lang="scss" scoped>
.UsageRtatistics {
  height: 100%;
  width: 100%;
  .Usage {
    display: flex;
    height: 100%;
    width: 100%;
  }
}
.UsageRtatistics_echart {
  width: 85% !important;
  height: 70%;
  padding-bottom:15px;
  padding-left:5px;
  margin: 20px 10px 0 24px;
  .UsageRtatistics_item {
    width: 80%;
    height: 100%;
  }
  .ivu-btn {
    margin: 0 6px;
  }
}
.UsageRtatistics_number {
  width: 35%;
  height: 255px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  margin: 40px 0 0 24px;
  padding-left: 24px;
  padding-top: 30px;
  .UsageRtatistics_number_icon {
    margin-bottom: 16px;
  }
  .number_icon {
    width: 36px;
    height: 36px;
    background: #3468fe;
    font-size: 24px;
    border-radius: 6px;
    background: rgba(52, 104, 254, 0.1);
    text-align: center;
    line-height: 36px;
    color: #3468fe;
    margin-right: 17px;
  }
  .number_num {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .iconfenxiang {
    color: #62cfe3;
    background: rgba(98, 207, 227, 0.1);
  }
  .iconxiazai {
    color: #ffae33;
    background: rgba(255, 174, 51, 0.1);
  }
  .iconshangchuan {
    color: #ff7e62;
    background: rgba(255, 126, 98, 0.1);
  }
}
.tab-data {
  &.active {
    background: #3468fe;
    color: white;
  }
}
</style>
