<template>
  <div class="costOverview">
    <h3 class="home-title">费用概览</h3>
    <div class="cost_info">
      <div class="cost_info_title">
        <span class="account">账户余额</span>
        <span class="account_num">{{costOver.balance}}元</span>
      </div>
      <div>
        <span class="account">本月消费</span>
        <span class="consumption_num">{{costOver.pay_amount}}元</span>
      </div>
    </div>
    <Row type="flex" justify="end">
    <Button class="flex-items-center" @click="onClickCharge">充值</Button>
    </Row>
  </div>
</template>
<script>
export default {
  name: "costOverview",
  props: {
    costOver:{}
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    // 充值
      onClickCharge(){
      this.$router.push({ name: 'charge'});
    },
  },
};
</script>
<style lang="scss" scoped>
.costOverview {
  padding: 20px 20px 0 24px;
  text-align: left;

  .cost_info {
    .cost_info_title {
      margin-bottom: 19px;
    }
    .account {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
      margin-right: 16px;
    }
    .account_num {
      height: 38px;
      font-size: 30px;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 38px;
    }
  }
.flex-items-center{
  background-color:#3468FE;
  color:#fff;
  width: 80px;
  border-radius: 4px;
  border: 1px solid #3468FE;
}
}
</style>
